<template>
  <header>
    <div id="navbar" style="padding: 80px 10px;">
      <div id="logo" >
          <a class="backUA" href="/#UA" style="font-size: larger;">IPUMON 
          <p style="float:right; font-size: small;">Coming Soon</p></a>
      </div>
    </div>
  </header>
</template>
<style>
#navbar {
  overflow: hidden;
  /* background-color: #f1f1f1; */
  padding: 90px 10px;
  transition: 0.4s;
  position: flex;
  width: 100%;
  top: 0;
  z-index: 99;
}

#navbar a {
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: 18px; 
  line-height: 25px;
  border-radius: 4px;
}

#navbar #logo {
  font-size: 35px;
  font-weight: bold;
  transition: 0.4s;
}

#navbar a:hover {
  background-color: #ddd;
  color: black;
}

#navbar a.active {
  background-color: dodgerblue;
  color: white;
}

#navbar-right {
  float: none;
}

@media screen and (max-width: 500px) {
  #navbar {
    padding: 20px 10px !important;
  }
  #navbar a {
    float: none;
    display: block;
    text-align: left;
  }
  #navbar-right {
    float: none;
  }
}
/* logo */
.button{
  float: left;
  color: black;
  text-align: center;
  padding: 12px;
  text-decoration: none;
  font-size: small; 
  background-color: dodgerblue;
  color: white;
}
</style>