 import { createRouter, createWebHistory } from 'vue-router'

const routes = [
  {
    path: '/:pathMatch(.*)*',
    name: 'Not Found',
    redirect: '/'
  },
  {
    path: '/',
    name: 'home',
    component: () => import('../views/HomeView.vue'),
    meta: { title: 'IPUMON' }
  },
  {
    path: '/p/:title',
    name: 'Post/ Pages',
    component: () => import('../views/Public/TitleView.vue'),
    meta: { title: 'IPUMON' }
  },
  {
    path: '/about',
    name: 'IPUMON-About',
    children: [
      { path: '/', name: 'about', component: () => import('../views/Public/About/AboutView.vue'), meta: { title: 'About IPUMON' }},
      { path: '/overview', name: 'Overview', component: () => import('../views/Public/About/OverView.vue'), meta: { title: 'Overview IPUMON' }},
      { path: '/policy', name: 'Policy', component: () => import('../views/Public/About/PolicyView.vue'), meta: { title: 'Policy IPUMON' }}, 
      { path: '/terms', name: 'Service_Terms', component: () => import('../views/Public/About/TermsView.vue'), meta: { title: 'Terms IPUMON' }},
    ],
  }, 
  {
    path: '/stars-order/',
    name: 'STARSytems',
    children: [
      { path: '', name: 'OrdersViewer', component: () => import('../views/AsyncSTARS/ReView.vue')},
      { path: ':TicketID', name: 'OrderViewer', component: () => import('../views/AsyncSTARS/ReView.vue')},
      { path: 'Terms-Policy', name: 'Terms-Policy', component: () => import('../views/AsyncSTARS/ReView.vue')},
    ],
  },
  // Games
  {
    path: '/Games/Numbers',
    name: 'Numbers',
    component: () => import('../views/Public/Games/CD-numbers.vue'),
    meta: { title: "IPUMON's Numbers" }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
